import { ROUTES_DICT } from "../../../routes/routesDict";

export const sampleCollectionRouteOptions = (location) => [
  {
    key: "Inicio",
    path: ROUTES_DICT.health.sampleCollection.list,
    icon: "home",
  },
  {
    key: "Agregar",
    path: ROUTES_DICT.health.sampleCollection.create,
    icon: "add",
  },
];
