import CustomDialog from "../../../../components/CustomDialog";
import SampleCollectionForm from "../Forms/SampleCollectionForm";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para agregar registros en `SampleCollection`.
 */

const SampleCollectionCreatePage = ({ parentPathname }) => {
  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <SampleCollectionForm
            type="create"
            onClickCancelButton={props.handleClose}
            onCompleteSubmit={props.handleClose}
          />
        )}
      </CustomDialog>
    </>
  );
};

export default SampleCollectionCreatePage;
