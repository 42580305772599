import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";
import geneticStockActions from "./geneticStock.actions";

const prefixRoute = "movement";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.MOVEMENT.RETRIEVE_LIST, payload: response });
};

const create = (data, geneticType) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const geneticStock = getState().geneticStock.list.find(
    (e) => e._id === data.geneticStockId
  );
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Movimiento registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.MOVEMENT.CREATE,
    payload: {
      ...response,
      total: response.quantity * response.unitValue,
      geneticStock,
    },
  });

  dispatch(
    geneticStockActions.listGeneticStockByAgribusiness({
      geneticType,
    })
  );
};

const transfer = (data, geneticType) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Transfer`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Movimiento registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch(list());
  dispatch(
    geneticStockActions.listGeneticStockByAgribusiness({
      geneticType,
    })
  );
};

const update = (data, geneticType) => async (dispatch, getState) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Movimiento actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.MOVEMENT.UPDATE_CURRENT, payload: response });

  dispatch(
    geneticStockActions.listGeneticStockByAgribusiness({
      geneticType,
    })
  );
  dispatch(list(geneticType));
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({ type: ACTION_TYPES.MOVEMENT.UPDATE_CURRENT, payload: response });
};

const MovementActions = {
  create,
  list,
  update,
  get,
  transfer,
};

export default MovementActions;

