import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "charts";

const get = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/milk-control`,
    body: { ...data, agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.MILKGRAPHIC.RETRIEVE, payload: response });
};

const MilkGraphicActions = {
  get,
};

export default MilkGraphicActions;
