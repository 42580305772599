import { format, isAfter, isBefore } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { Grid } from "@material-ui/core";

export const columns = [
  {
    label: "ID. del animal",
    name: "animalIdentifier",
    options: {
      filter: false,
    },
  },
  {
    label: "Nombre del animal",
    name: "animalName",
    options: {
      filter: false,
    },
  },
  {
    label: "Tipo de muestra",
    name: "sampleType",
    options: {
      filterOptions: {
        names: ["HAIR", "BLOOD", "OTHER"],
      },
      customBodyRender: (value) => {
        const sampleTypeDictionary = {
          HAIR: "Pelo",
          BLOOD: "Sangre",
          OTHER: "Otro",
        };
        return sampleTypeDictionary[value] || value;
      },
    },
  },
  // {
  //   label: "Comentarios",
  //   name: "comments",
  //   options: {
  //     filter: false,
  //   },
  // },
  {
    label: "Responsable",
    name: "responsibleName",
    options: {
      filter: false,
    },
  },
  {
    label: "Fecha de recolección",
    name: "date",
    options: {
      searchable: false,
      filterType: "custom",
      customBodyRender: (value) => {
        return value ? format(new Date(value), "yyyy-MM-dd") : "-";
      },
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return `Desde: ${format(v[0], "yyyy-MM-dd")}, Hasta: ${format(
              v[1],
              "yyyy-MM-dd"
            )}`;
          } else if (v[0]) {
            return `Desde: ${format(v[0], "yyyy-MM-dd")}`;
          } else if (v[1]) {
            return `Hasta: ${format(v[1], "yyyy-MM-dd")}`;
          }
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, "");
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }
          return filterList;
        },
      },
      filterOptions: {
        names: [],
        logic(date, filters) {
          if (filters[0] && filters[1]) {
            return (
              isBefore(new Date(date), new Date(filters[0])) ||
              isAfter(new Date(date), new Date(filters[1]))
            );
          } else if (filters[0]) {
            return isBefore(new Date(date), new Date(filters[0]));
          } else if (filters[1]) {
            return isAfter(new Date(date), new Date(filters[1]));
          }
          return false;
        },
        display: (filterList, onChange, index, column) => {
          return (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <DatePicker
                  value={filterList[index][0]}
                  onChange={(date) => {
                    filterList[index][0] = date;
                    onChange(filterList[index], index, column);
                  }}
                  label="Desde"
                  clearable
                  format="yyyy-MM-dd"
                  clearLabel="LIMPIAR"
                  cancelLabel="CANCELAR"
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  value={filterList[index][1]}
                  onChange={(date) => {
                    filterList[index][1] = date;
                    onChange(filterList[index], index, column);
                  }}
                  label="Hasta"
                  clearable
                  format="yyyy-MM-dd"
                  clearLabel="LIMPIAR"
                  cancelLabel="CANCELAR"
                />
              </Grid>
            </Grid>
          );
        },
      },
    },
  },
];
