import ACTION_TYPES from "../types";
import AnimalActions from "./animal.actions";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "palpationControl";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.PALPATION.RETRIEVE, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({
    type: ACTION_TYPES.PALPATION.UPDATE_CURRENT,
    payload: response,
  });
};

const create = (data, animal, user) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Control de palpacion registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuavamente.",
  });

  dispatch(AnimalActions.list());

  console.log("antes");
  dispatch({
    type: ACTION_TYPES.PALPATION.CREATE,
    payload: { ...data, animal: animal, user: user },
  });
  console.log("despues");
};

const update = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Control de palpacion actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuavamente.",
  });

  dispatch({
    type: ACTION_TYPES.PALPATION.UPDATE_CURRENT,
    payload: response,
  });
  dispatch(list());
};

const deletePalpation = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Control de palpacion eliminado exitosamente.",
    errorMessage: "Error desconocido, intente nuavamente.",
  });

  dispatch({ type: ACTION_TYPES.PALPATION.DELETE, payload: response });
  dispatch(list());
};

const PalpationActions = {
  list,
  get,
  create,
  update,
  deletePalpation,
};

export default PalpationActions;
