import React, { useState } from "react";
import CustomDialog from "../../../components/CustomDialog";
import IAMNForm from "../Forms/IAMNForm";
import CollaboratorForms from "../../HaciendaConfigurationPage/Forms/CollaboratorForm";

const IAMNCreatePage = ({ parentPathname }) => {
  const [openCollaboratorDialog, setOpenCollaboratorDialog] = useState(false);

  const handleOpenCollaborator = () => {
    console.log("openCollaborator");
    setOpenCollaboratorDialog(true);
  };

  const handleCloseCollaborator = () => {
    console.log("closeCollaborator");
    setOpenCollaboratorDialog(false);
  };
  return (
    <>
      <CustomDialog parentPathName={parentPathname}>
        {(dialogProps) => (
          <>
            <IAMNForm
              type="create"
              onCompleteSubmit={dialogProps.handleClose}
              onClickCancelButton={dialogProps.handleClose}
              onOpenCollaborator={handleOpenCollaborator}
            />
          </>
        )}
      </CustomDialog>

      {openCollaboratorDialog && (
        <CustomDialog
          parentPathname={parentPathname}
          maxWidth="md"
          onClose={handleCloseCollaborator}
        >
          {(collabDialogProps) => (
            <CollaboratorForms
              type="create"
              onClickCancelButton={() => {
                // collabDialogProps.handleClose();
                handleCloseCollaborator();
              }}
              onCompleteSubmit={() => {
                // collabDialogProps.handleClose();
                handleCloseCollaborator();
              }}
            />
          )}
        </CustomDialog>
      )}
    </>
  );
};

export default IAMNCreatePage;
