import ApiVL from "../helpers/virtualLabsApi";

const prefixRouteFarm = "farm";
const prefixRouteUser = "user";

const farmCreate = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteFarm}/Create`,
      body: data,
      successMessage: "Finca registrada satisfactoriamente.",
      errorMessage: "Error desconocido, intente nuevamente.",
    });
    localStorage.setItem("farm", JSON.stringify(response));
    return response;
  } catch (error) {
    throw error;
  }
};

const update = async (data) => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteFarm}/Update`,
      body: data,
      successMessage: "Finca actualizada satisfactoriamente.",
      errorMessage: "Error desconocido, intente nuevamente.",
    });
    localStorage.setItem("farm", JSON.stringify(response));
    return response;
  } catch (error) {
    throw error;
  }
};

async function findByOwnerId(ownerId) {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRouteUser}/GetById`,
      body: { _id: ownerId },
    });
    return response?.farm;
  } catch (error) {
    const rejectBody = {
      message:
        (error.responseJSON && error.responseJSON.body) || "Error desconocido",
    };
    throw rejectBody;
  }
}
const FarmService = {
  farmCreate,
  findByOwnerId,
  update,
};

export default FarmService;
