import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "comment";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.COMMENT.RETRIEVE_LIST, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({
    type: ACTION_TYPES.COMMENT.UPDATE_CURRENT,
    payload: response,
  });
};

const create = (data) => async (dispatch, getState) => {
  const user = getState().auth.user;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, userId: user._id },
    dispatch: dispatch,
    successMessage: "Comentario registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.COMMENT.CREATE,
    payload: response,
  });
};

const update = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Comentario actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.COMMENT.UPDATE_CURRENT,
    payload: response,
  });

  dispatch(list());
};

const deleteById = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Comentario eliminado exitosamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.COMMENT.DELETE, payload: response });
  dispatch(list());
};

const CommentActions = {
  list,
  get,
  create,
  update,
  deleteById,
};

export default CommentActions;
