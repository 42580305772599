import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";
import AnimalActions from "./animal.actions";

const prefixRoute = "zealControl";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.ZEAL.RETRIEVE, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({
    type: ACTION_TYPES.ZEAL.UPDATE_CURRENT,
    payload: response,
  });
};

const create = (data, animal) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Colectiva celo registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch(AnimalActions.list());
  dispatch({
    type: ACTION_TYPES.ZEAL.CREATE,
    payload: { ...response, animal: animal },
  });
};

const update = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Colectiva celo actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.ZEAL.UPDATE_CURRENT,
    payload: response,
  });
  dispatch(list());
};

const deleteZeal = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Colectiva celo eliminado exitosamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.ZEAL.DELETE, payload: response });
  dispatch(list());
};

const ZealActions = {
  list,
  get,
  create,
  update,
  deleteZeal,
};

export default ZealActions;

