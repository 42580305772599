import CustomDialog from "../../../../components/CustomDialog";
import SupplementApplicationForm from "../Forms/SupplementApplicationForm";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para agregar registros en `SupplementApplication`.
 */

const SupplementApplicationCreatePage = ({ parentPathname }) => {
  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <SupplementApplicationForm
            type="create"
            onClickCancelButton={props.handleClose}
            onCompleteSubmit={props.handleClose}
          />
        )}
      </CustomDialog>
    </>
  );
};

export default SupplementApplicationCreatePage;
