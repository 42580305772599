import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "sampleCollection";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });
  dispatch({
    type: ACTION_TYPES.SAMPLECOLLECTION.RETRIEVE,
    payload: response,
  });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });
  dispatch({
    type: ACTION_TYPES.SAMPLECOLLECTION.UPDATE_CURRENT,
    payload: response,
  });
  return response;
};

const clearCurrent = () => (dispatch) => {
  dispatch({
    type: ACTION_TYPES.SAMPLECOLLECTION.UPDATE_CURRENT,
    payload: null,
  });
};

const create = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch,
    successMessage: "Muestra registrada satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.SAMPLECOLLECTION.CREATE, payload: response });
  dispatch(list());
  return response;
};

const update = (data) => async (dispatch) => {
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch,
    successMessage: "Muestra actualizada satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });
  dispatch({
    type: ACTION_TYPES.SAMPLECOLLECTION.UPDATE_CURRENT,
    payload: data,
  });
  dispatch(list());
};

const remove = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: data,
    dispatch,
    successMessage: "Muestra eliminada satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });
  dispatch({ type: ACTION_TYPES.SAMPLECOLLECTION.DELETE, payload: response });
  dispatch(list());
};

const SampleCollectionActions = {
  list,
  get,
  create,
  update,
  clearCurrent,
  remove,
};

export default SampleCollectionActions;
