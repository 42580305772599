import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "user";

const list = () => async (dispatch, getState) => {
  const farm = getState().farm.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByFarm`,
    body: { farmId: farm?._id },
  });

  dispatch({ type: ACTION_TYPES.USER.RETRIEVE, payload: response });
};

const create = (data) => async (dispatch, getState) => {
  const farm = getState().farm.current;

  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, farmId: farm._id },
    dispatch: dispatch,
    successMessage: "Usuario registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.USER.CREATE,
    payload: response,
  });
};

const update = (data) => async (dispatch) => {
  await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Usuario actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.USER.UPDATE_CURRENT, payload: data });
  dispatch(list());
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({ type: ACTION_TYPES.USER.UPDATE_CURRENT, payload: response });
  return response;
};

const deleteUser = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: data,
    dispatch: dispatch,
    successMessage: "Usuario eliminado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.USER.DELETE, payload: response });

  dispatch(list());
};

const UserActions = {
  create,
  list,
  update,
  get,
  deleteUser,
};

export default UserActions;
