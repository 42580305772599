import * as yup from "yup";
import React, { useEffect } from "react";
import { Formik } from "formik";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { useStyles } from "../styles";
import { injectableUnitsOptions } from "../../../../constants";

import ButtonFormik from "../../../../components/Inputs/ButtonFormik";
import CustomModal from "../../../../components/Modal";
import TextFieldFormik from "../../../../components/Inputs/TextFieldFormik";
import SelectFieldFormik from "../../../../components/Inputs/SelectFieldFormik";
import SearchFieldFormik from "../../../../components/Inputs/SearchFieldFormik";
import AutocompleteFieldFormik from "../../../../components/Inputs/AutocompleteFieldFormik";
import DatePickerFieldFormik from "../../../../components/Inputs/DatePickerFieldFormik";

import AnimalActions from "../../../../redux/actions/animal.actions";
import SupplementApplicationActions from "../../../../redux/actions/supplementApplication.actions";
import VaccinationProductActions from "../../../../redux/actions/vaccinationProduct.actions";

import {
  supplementTypeOptions,
  administrationRouteOptions,
} from "../../../../constants";

const defaultInitValues = {
  agribusinessId: "",
  animalId: "",
  date: new Date(),
  type: "VACCINE",
  product: "",
  quantity: 1,
  unit: "",
  administrationRoute: "INTRAMUSCULAR",
};

function SupplementApplicationForm({
  initValues = defaultInitValues,
  type = "create",
  onClickCancelButton,
  onCompleteSubmit = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const listAnimals = useSelector((state) => state.animal.list);
  const vaccinationProducts = useSelector(
    (state) => state.vaccinationProduct.list
  );
  const currentAgribusiness = useSelector(
    (state) => state.agribusiness.current
  );

  const validationSchema = yup.object().shape({
    animalId: yup.string().required("Seleccione un animal."),
    date: yup
      .date()
      .max(new Date(), "No puede seleccionar una fecha futura.")
      .required("Ingrese la fecha."),
    type: yup.string().required("Seleccione un tipo de aplicación."),
    product: yup.string().required("Ingrese el nombre del producto."),
    quantity: yup
      .number()
      .typeError("Ingrese un número válido.")
      .min(1, "La cantidad mínima es 1.")
      .required("Ingrese la cantidad."),
    unit: yup.string().required("Ingrese la unidad."),
    administrationRoute: yup
      .string()
      .required("Seleccione una ruta de administración."),
  });

  useEffect(() => {
    if (!listAnimals || listAnimals.length === 0) {
      dispatch(AnimalActions.list());
    }
    if (!vaccinationProducts || vaccinationProducts.length === 0) {
      dispatch(VaccinationProductActions.list());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listAnimals]);

  const preSubmit = (values, actions) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    values.agribusinessId = currentAgribusiness._id;

    if (type === "create") {
      dispatch(SupplementApplicationActions.create(values))
        .then(() => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error creating supplement application:", error);
        });
    }
    if (type === "update") {
      dispatch(SupplementApplicationActions.update(values))
        .then(() => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error updating supplement application:", error);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={preSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.formStyle}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {type === "create" && "Nueva aplicación de suplemento"}
                {type === "update" && "Editar aplicación de suplemento"}
              </Typography>
            </Grid>
            <Grid container spacing={1} className={classes.formStyle}>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>
                  Datos de la Aplicación
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <SearchFieldFormik
                onChange={(event, value) =>
                  props.setFieldValue("animalId", value ? value._id : "")
                }
                options={listAnimals.map((animal) => ({
                  _id: animal._id,
                  identifier: `${animal.identifier} - ${animal.name}`,
                }))}
                label="Animal"
                name="animalId"
                lg={6}
                sm={6}
                xs={12}
                disabled={type === "create" ? false : true}
                defaultValue={type === "create" ? null : props.values.animal}
              ></SearchFieldFormik>
              <DatePickerFieldFormik
                label="Fecha de aplicación"
                name="date"
                onChange={props.handleChange}
                lg={6}
                sm={6}
                xs={12}
              ></DatePickerFieldFormik>
              <SelectFieldFormik
                onChange={props.handleChange}
                options={supplementTypeOptions}
                label="Tipo de aplicación"
                name="type"
                lg={6}
                sm={6}
                xs={12}
              ></SelectFieldFormik>
              <AutocompleteFieldFormik
                freeSolo
                options={vaccinationProducts.map((product) => ({
                  _id: product.name,
                  identifier: product.name,
                }))}
                displayName={false}
                name="product"
                label="Producto"
                onChange={(e, value) => {
                  props.setFieldValue(
                    "product",
                    typeof value === "string" ? value : value?.name || ""
                  );
                }}
                onInputChange={(e, value) => {
                  props.setFieldValue("product", value);
                }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option?.identifier || ""
                }
                // getOptionSelected={(option, value) => option._id === value}
                value={props.values.product}
                defaultValue={type === "create" ? "" : props.values.product}
                lg={6}
                sm={6}
                xs={12}
              />
              <TextFieldFormik
                label="Cantidad"
                name="quantity"
                type="number"
                onChange={props.handleChange}
                lg={6}
                sm={6}
                xs={12}
              ></TextFieldFormik>
              <Grid item xs={6} sm={6} lg={6}>
                <SelectFieldFormik
                  onChange={props.handleChange}
                  options={injectableUnitsOptions}
                  label="Unidad de medida"
                  name="unit"
                  fullWidth
                ></SelectFieldFormik>
              </Grid>
              <SelectFieldFormik
                onChange={props.handleChange}
                options={administrationRouteOptions}
                label="Ruta de administración"
                name="administrationRoute"
                lg={6}
                sm={6}
                xs={12}
              ></SelectFieldFormik>
            </Grid>
            <Grid container spacing={1}>
              <Grid item container xs={12} justifyContent={"flex-end"}>
                <Grid item xs={3} className={classes.paddingButton}>
                  <ButtonFormik
                    xs={3}
                    label="Cancelar"
                    type="cancel"
                    onClick={onClickCancelButton}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonFormik xs={3} label="Guardar" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <CustomModal />
    </>
  );
}

export default SupplementApplicationForm;
