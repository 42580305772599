import ACTION_TYPES from "../types";
import UserActions from "./user.actions";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRouteAgribusiness = "agribusiness";
const prefixRouteSaleControl = "saleControl";

const list = () => async (dispatch, getState) => {
  const farm = getState().farm.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRouteAgribusiness}/ListByFarmId`,
    body: { farmId: farm._id },
  });

  dispatch({ type: ACTION_TYPES.AGRIBUSINESS.RETRIEVE, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRouteSaleControl}/GetById`,
    body: data,
  });

  dispatch({
    type: ACTION_TYPES.AGRIBUSINESS.UPDATE,
    payload: response,
  });
};

const create = (data) => async (dispatch, getState) => {
  const auth = getState().auth;
  const user = await dispatch(UserActions.get({ _id: auth.user._id }));
  console.log(user);
  const response = await ApiVL.fetch({
    serviceName: `${prefixRouteAgribusiness}/Create`,
    body: { ...data, farmId: user.farmId },
    dispatch: dispatch,
    successMessage: "Tu agronegocio fue creado exitosamente",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.AGRIBUSINESS.CREATE,
    payload: response,
  });
};

const update = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRouteAgribusiness}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Tu agronegocio fue actualizado exitosamente",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.AGRIBUSINESS.UPDATE,
    payload: response,
  });
  dispatch(list());
};

const deleteAgribusiness = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRouteSaleControl}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Tu agronegocio fue eliminado exitosamente",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.SALE.DELETE, payload: response });
  dispatch(list());
};

const setCurrentAgribusiness = (data) => {
  return (dispatch) => {
    localStorage.setItem("agribusiness", JSON.stringify(data));
    localStorage.setItem("reproductiveManagement", data.reproductiveManagement);
    dispatch({
      type: ACTION_TYPES.AGRIBUSINESS.UPDATE_CURRENT,
      payload: data,
    });
  };
};

const AgribusinessActions = {
  list,
  get,
  create,
  update,
  deleteAgribusiness,
  setCurrentAgribusiness,
};

export default AgribusinessActions;
