import ApiVL from "../helpers/virtualLabsApi";

class CurrencyService {
  constructor() {
    this.prefixRoute = "currency";
  }

  async getAll() {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${this.prefixRoute}/List`,
        body: {},
      });
      return response;
    } catch (error) {
      throw {
        message:
          (error.responseJSON && error.responseJSON.body) ||
          "Error desconocido",
      };
    }
  }

  async get(_id) {
    try {
      const response = await ApiVL.fetch({
        serviceName: `${this.prefixRoute}/GetById`,
        body: { _id },
      });
      return response;
    } catch (error) {
      throw {
        message:
          (error.responseJSON && error.responseJSON.body) ||
          "Error desconocido",
      };
    }
  }

  create() {}
  update() {}
  delete() {}
  deleteAll() {}
  findByName(name) {}
}

export default new CurrencyService();
