import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import TableButtons from "../../../../components/TableButtons";
import CustomMuiTable from "../../../../components/CustomMuiTable";

import AddSupplementApplication from "../AddSupplementApplication";

import { columns } from "./constants";
import { supplementApplicationRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../../routes/routesDict";

import SupplementApplicationActions from "../../../../redux/actions/supplementApplication.actions";

const SupplementApplicationPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listSupplementApplication = useSelector(
    (state) => state.supplementApplication.list
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    (async () => {
      setTitle("Control de Suplementos");
      setChipList(supplementApplicationRouteOptions(location));
      if (
        !listSupplementApplication ||
        listSupplementApplication.length === 0
      ) {
        setIsLoading(true);
        await dispatch(SupplementApplicationActions.list());
      }
      console.log("Supplement Application Data:", listSupplementApplication);

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "animalIdentifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <TableButtons
            onClickDeleteButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.supplementApplication.delete, {
                  ...params,
                  _id: listSupplementApplication[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.supplementApplication.update, {
                  ...params,
                  _id: listSupplementApplication[dataIndex]._id,
                })
              );
            }}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      <AddSupplementApplication
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listSupplementApplication}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default SupplementApplicationPageList;
