import * as yup from "yup";
import React, { useEffect } from "react";
import { Formik } from "formik";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { sampleTypeOptions } from "../../../../constants";

import { useStyles } from "../styles";

import ButtonFormik from "../../../../components/Inputs/ButtonFormik";
import CustomModal from "../../../../components/Modal";
import TextFieldFormik from "../../../../components/Inputs/TextFieldFormik";
import SelectFieldFormik from "../../../../components/Inputs/SelectFieldFormik";
import SearchFieldFormik from "../../../../components/Inputs/SearchFieldFormik";
import DatePickerFieldFormik from "../../../../components/Inputs/DatePickerFieldFormik";

import AnimalActions from "../../../../redux/actions/animal.actions";
import SampleCollectionActions from "../../../../redux/actions/sampleCollection.actions";
import CollaboratorActions from "../../../../redux/actions/collaborator.actions";

const defaultInitValues = {
  agribusinessId: "",
  animalId: "",
  date: new Date(),
  sampleType: "BLOOD",
  comments: "",
  userId: "",
};

function SampleCollectionForm({
  initValues = defaultInitValues,
  type = "create",
  onClickCancelButton,
  onCompleteSubmit = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const listAnimals = useSelector((state) => state.animal.list);
  const listCollaborators = useSelector((state) => state.collaborator.list);
  const currentAgribusiness = useSelector(
    (state) => state.agribusiness.current
  );

  const validationSchema = yup.object().shape({
    animalId: yup.string().required("Seleccione un animal."),
    date: yup
      .date()
      .max(new Date(), "No puede seleccionar una fecha futura.")
      .required("Ingrese la fecha."),
    sampleType: yup.string().required("Seleccione un tipo de muestra."),
    userId: yup.string().required("Seleccione un colaborador."),
    comments: yup.string(),
  });

  useEffect(() => {
    if (!listAnimals || listAnimals.length === 0) {
      dispatch(AnimalActions.list());
    }
    if (!listCollaborators || listCollaborators.length === 0) {
      dispatch(CollaboratorActions.list());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listAnimals, listCollaborators]);

  const preSubmit = (values, actions) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    values.agribusinessId = currentAgribusiness._id;

    if (type === "create") {
      dispatch(SampleCollectionActions.create(values))
        .then(() => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error creating sample collection:", error);
        });
    }
    if (type === "update") {
      dispatch(SampleCollectionActions.update(values))
        .then((r) => {
          onClickCancelButton();
        })
        .catch((error) => {
          console.error("Error updating sample collection:", error);
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={preSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={classes.formStyle}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {type === "create" && "Nueva muestra"}
                {type === "update" && "Editar muestra"}
              </Typography>
            </Grid>
            <Grid container spacing={1} className={classes.formStyle}>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>
                  Datos de la Muestra
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <SearchFieldFormik
                onChange={(event, value) =>
                  props.setFieldValue("animalId", value ? value._id : "")
                }
                options={listAnimals.map((animal) => ({
                  _id: animal._id,
                  identifier: `${animal.identifier} - ${animal.name}`,
                }))}
                label="Animal"
                name="animalId"
                lg={6}
                sm={6}
                xs={12}
                disabled={type === "create" ? false : true}
                defaultValue={type === "create" ? null : props.values.animal}
              ></SearchFieldFormik>
              <DatePickerFieldFormik
                label="Fecha de recolección"
                name="date"
                onChange={props.handleChange}
                lg={6}
                sm={6}
                xs={12}
              ></DatePickerFieldFormik>
              <SelectFieldFormik
                onChange={props.handleChange}
                options={sampleTypeOptions}
                label="Tipo de muestra"
                name="sampleType"
                lg={6}
                sm={6}
                xs={12}
              ></SelectFieldFormik>
              <SelectFieldFormik
                onChange={props.handleChange}
                options={listCollaborators}
                label="Colaborador"
                name="userId"
                lg={6}
                sm={6}
                xs={12}
              ></SelectFieldFormik>
              <TextFieldFormik
                label="Comentarios"
                name="comments"
                type="text"
                onChange={props.handleChange}
                lg={12}
                sm={12}
                xs={12}
              ></TextFieldFormik>
            </Grid>
            <Grid container spacing={1}>
              <Grid item container xs={12} justifyContent={"flex-end"}>
                <Grid item xs={3} className={classes.paddingButton}>
                  <ButtonFormik
                    xs={3}
                    label="Cancelar"
                    type="cancel"
                    onClick={onClickCancelButton}
                  />
                </Grid>
                <Grid item xs={3}>
                  <ButtonFormik xs={3} label="Guardar" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <CustomModal />
    </>
  );
}

export default SampleCollectionForm;
