import ACTION_TYPES from "../types";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "milkControl";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.MILKCONTROL.RETRIEVE, payload: response });
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({
    type: ACTION_TYPES.MILKCONTROL.UPDATE_CURRENT,
    payload: response,
  });
};

const create = (data, animal) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Control lechero registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.MILKCONTROL.CREATE,
    payload: { ...response, animal: animal },
  });
};
const update = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: data,
    dispatch: dispatch,
    successMessage: "Control lechero actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({
    type: ACTION_TYPES.MILKCONTROL.UPDATE_CURRENT,
    payload: response,
  });
  dispatch(list());
};

const deleteMilkControl = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Delete`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Control lecheros eliminado exitosamente.",
    errorMessage: "Error desconocido, intente nuevamente.",
  });

  dispatch({ type: ACTION_TYPES.MILKCONTROL.DELETE, payload: response });
  dispatch(list());
};

const MilkActions = {
  list,
  get,
  create,
  update,
  deleteMilkControl,
};

export default MilkActions;
