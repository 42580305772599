import ApiVL from "../helpers/virtualLabsApi";

const prefixRoute = "country";

const getAll = async () => {
  try {
    const response = await ApiVL.fetch({
      serviceName: `${prefixRoute}/List`,
      body: {},
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const CountryService = {
  getAll,
};

export default CountryService;
