import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import SupplementApplicationActions from "../../../../redux/actions/supplementApplication.actions";
import SupplementApplicationForm from "../Forms/SupplementApplicationForm";
import CustomDialog from "../../../../components/CustomDialog";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para editar registros en `SupplementApplication`.
 */

const SupplementApplicationUpdatePage = ({ parentPathname }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentSupplementApplication = useSelector(
    (state) => state.supplementApplication.current
  );

  useEffect(() => {
    if (
      !currentSupplementApplication ||
      currentSupplementApplication._id !== params._id
    ) {
      dispatch(SupplementApplicationActions.get({ _id: params._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <>
            {currentSupplementApplication &&
              currentSupplementApplication._id &&
              currentSupplementApplication._id === params._id && (
                <SupplementApplicationForm
                  type="update"
                  initValues={currentSupplementApplication}
                  onClickCancelButton={props.handleClose}
                  onCompleteSubmit={props.handleClose}
                />
              )}
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default SupplementApplicationUpdatePage;
