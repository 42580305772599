import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
  useParams,
  generatePath,
} from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import TableButtons from "../../../../components/TableButtons";
import CustomMuiTable from "../../../../components/CustomMuiTable";

import AddSampleCollection from "../AddSampleCollection";

import { columns } from "./constants";
import { sampleCollectionRouteOptions } from "../constants";
import { ROUTES_DICT } from "../../../../routes/routesDict";

import SampleCollectionActions from "../../../../redux/actions/sampleCollection.actions";

const SampleCollectionPageList = ({ children, setTitle, setChipList }) => {
  const history = useHistory();
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const listSampleCollection = useSelector(
    (state) => state.sampleCollection.list
  );

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    (async () => {
      setTitle("Control de Muestras");
      setChipList(sampleCollectionRouteOptions(location));
      if (!listSampleCollection || listSampleCollection.length === 0) {
        setIsLoading(true);
        await dispatch(SampleCollectionActions.list());
      }
      console.log("Sample Collection Data", listSampleCollection);

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = {
    selectableRows: "none",
    searchText,
    search: false,
    sortOrder: {
      name: "animalIdentifier",
      direction: "asc",
    },
  };

  const actionColumn = {
    label: "Acciones",
    name: "actions",
    options: {
      searchable: false,
      filter: false,
      sort: false,
      customBodyRenderLite: (dataIndex) => {
        return (
          <TableButtons
            onClickDeleteButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.sampleCollection.delete, {
                  ...params,
                  _id: listSampleCollection[dataIndex]._id,
                })
              );
            }}
            onClickEditButton={() => {
              history.push(
                generatePath(ROUTES_DICT.health.sampleCollection.update, {
                  ...params,
                  _id: listSampleCollection[dataIndex]._id,
                })
              );
            }}
          />
        );
      },
    },
  };

  return (
    <Grid item container xs={12}>
      <AddSampleCollection
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <Grid item xs={12} className={classes.registerContainer}>
        <CustomMuiTable
          data={listSampleCollection}
          columns={[...columns, actionColumn]}
          options={options}
          isLoading={isLoading}
        />
      </Grid>
      {children()}
    </Grid>
  );
};

export default SampleCollectionPageList;
