import ACTION_TYPES from "../types";
import AnimalActions from "./animal.actions";
import ApiVL from "../../helpers/virtualLabsApi";

const prefixRoute = "birthControl";

const list = () => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/ListByAgribusiness`,
    body: { agribusinessId: agribusiness?._id },
  });

  dispatch({ type: ACTION_TYPES.BIRTH.RETRIEVE_LIST, payload: response });
};

const create = (data) => async (dispatch, getState) => {
  const agribusiness = getState().agribusiness.current;
  const animal = getState().animal.list.find((e) => e._id === data.animalId);

  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Create`,
    body: { ...data, agribusinessId: agribusiness._id },
    dispatch: dispatch,
    successMessage: "Nacimiento registrado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuavamente.",
  });

  dispatch({
    type: ACTION_TYPES.BIRTH.CREATE,
    payload: { ...data, animal: animal },
  });

  dispatch(AnimalActions.list());
  return response;
};

const update = (data, geneticType) => async (dispatch, getState) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/Update`,
    body: { ...data },
    dispatch: dispatch,
    successMessage: "Nacimiento actualizado satisfactoriamente.",
    errorMessage: "Error desconocido, intente nuavamente.",
  });

  dispatch({ type: ACTION_TYPES.BIRTH.UPDATE_CURRENT, payload: response });
  dispatch(list());
};

const get = (data) => async (dispatch) => {
  const response = await ApiVL.fetch({
    serviceName: `${prefixRoute}/GetById`,
    body: data,
  });

  dispatch({ type: ACTION_TYPES.BIRTH.UPDATE_CURRENT, payload: response });
};

const BirthActions = {
  create,
  list,
  update,
  get,
};

export default BirthActions;
