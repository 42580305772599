import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import SampleCollectionActions from "../../../../redux/actions/sampleCollection.actions";
import SampleCollectionForm from "../Forms/SampleCollectionForm";
import CustomDialog from "../../../../components/CustomDialog";

/**
 * @component
 * @description Componente, dialog o modal que contiene el formulario para editar registros en `SampleCollection`.
 */

const SampleCollectionUpdatePage = ({ parentPathname }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentSampleCollection = useSelector(
    (state) => state.sampleCollection.current
  );

  useEffect(() => {
    if (
      !currentSampleCollection ||
      currentSampleCollection._id !== params._id
    ) {
      dispatch(SampleCollectionActions.get({ _id: params._id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <CustomDialog parentPathname={parentPathname}>
        {(props) => (
          <>
            {currentSampleCollection &&
              currentSampleCollection._id &&
              currentSampleCollection._id === params._id && (
                <SampleCollectionForm
                  type="update"
                  initValues={currentSampleCollection}
                  onClickCancelButton={props.handleClose}
                  onCompleteSubmit={props.handleClose}
                />
              )}
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default SampleCollectionUpdatePage;
